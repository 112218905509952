import {FC} from 'react';

interface AmazonProps {
  width?: number;
  height?: number;
}

export const Amazon: FC<AmazonProps> = ({width = 18, height = 19}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#161D27" />
      <g clipPath="url(#clip0_13731_54244)">
        <path
          d="M11.6094 11.0725C7.56143 12.999 5.04921 11.3871 3.44105 10.4081C3.34154 10.3464 3.17241 10.4226 3.31915 10.5911C3.85491 11.2407 5.6107 12.8065 7.90251 12.8065C10.1959 12.8065 11.5603 11.5551 11.7309 11.3368C11.9004 11.1204 11.7807 11.001 11.6094 11.0725H11.6094ZM12.7463 10.4446C12.6376 10.3031 12.0853 10.2767 11.7377 10.3194C11.3896 10.3609 10.8671 10.5736 10.9125 10.7014C10.9358 10.7492 10.9834 10.7277 11.2225 10.7062C11.4623 10.6823 12.1341 10.5975 12.2741 10.7805C12.4147 10.9648 12.0598 11.8425 11.995 11.984C11.9324 12.1256 12.0189 12.1621 12.1366 12.0678C12.2526 11.9736 12.4626 11.7295 12.6035 11.3841C12.7435 11.0369 12.8289 10.5524 12.7463 10.4446Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.88565 7.14249C8.88565 7.64798 8.89842 8.06953 8.64293 8.51843C8.43671 8.88343 8.11006 9.10788 7.74509 9.10788C7.24688 9.10788 6.95673 8.7283 6.95673 8.16808C6.95673 7.06218 7.94761 6.86146 8.88565 6.86146V7.14249ZM10.1941 10.305C10.1083 10.3816 9.9842 10.3871 9.8875 10.336C9.45683 9.97837 9.38017 9.81231 9.14295 9.47104C8.43125 10.1974 7.92757 10.4145 7.00419 10.4145C5.91291 10.4145 5.0625 9.74111 5.0625 8.39253C5.0625 7.33959 5.63372 6.62239 6.44574 6.27204C7.15017 5.96178 8.13379 5.90704 8.88565 5.8213V5.65339C8.88565 5.34497 8.90934 4.98001 8.72871 4.71359C8.56992 4.47452 8.267 4.37597 8.00054 4.37597C7.50602 4.37597 7.06439 4.62962 6.95673 5.15518C6.9348 5.272 6.84906 5.38698 6.73228 5.39244L5.47309 5.25742C5.36727 5.23365 5.25048 5.14791 5.27968 4.98547C5.56983 3.4599 6.94757 3 8.18118 3C8.8126 3 9.63743 3.1679 10.1356 3.64604C10.7671 4.23546 10.7068 5.02197 10.7068 5.87784V7.89981C10.7068 8.5075 10.9587 8.77392 11.1959 9.10242C11.2798 9.2192 11.2981 9.35976 11.1922 9.44731C10.9276 9.6681 10.4568 10.0787 10.1977 10.3087L10.194 10.305"
          fill="black"
        />
        <path
          d="M11.6094 11.0725C7.56143 12.999 5.04921 11.3871 3.44105 10.4081C3.34154 10.3464 3.17241 10.4226 3.31915 10.5911C3.85491 11.2407 5.6107 12.8065 7.90251 12.8065C10.1959 12.8065 11.5603 11.5551 11.7309 11.3368C11.9004 11.1204 11.7807 11.001 11.6094 11.0725H11.6094ZM12.7463 10.4446C12.6376 10.3031 12.0853 10.2767 11.7377 10.3194C11.3896 10.3609 10.8671 10.5736 10.9125 10.7014C10.9358 10.7492 10.9834 10.7277 11.2225 10.7062C11.4623 10.6823 12.1341 10.5975 12.2741 10.7805C12.4147 10.9648 12.0598 11.8425 11.995 11.984C11.9324 12.1256 12.0189 12.1621 12.1366 12.0678C12.2526 11.9736 12.4626 11.7295 12.6035 11.3841C12.7435 11.0369 12.8289 10.5524 12.7463 10.4446Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.88565 7.14249C8.88565 7.64798 8.89842 8.06953 8.64293 8.51843C8.43671 8.88343 8.11006 9.10788 7.74509 9.10788C7.24688 9.10788 6.95673 8.7283 6.95673 8.16808C6.95673 7.06218 7.94761 6.86146 8.88565 6.86146V7.14249ZM10.1941 10.305C10.1083 10.3816 9.9842 10.3871 9.8875 10.336C9.45683 9.97837 9.38017 9.81231 9.14295 9.47104C8.43125 10.1974 7.92757 10.4145 7.00419 10.4145C5.91291 10.4145 5.0625 9.74111 5.0625 8.39253C5.0625 7.33959 5.63372 6.62239 6.44574 6.27204C7.15017 5.96178 8.13379 5.90704 8.88565 5.8213V5.65339C8.88565 5.34497 8.90934 4.98001 8.72871 4.71359C8.56992 4.47452 8.267 4.37597 8.00054 4.37597C7.50602 4.37597 7.06439 4.62962 6.95673 5.15518C6.9348 5.272 6.84906 5.38698 6.73228 5.39244L5.47309 5.25742C5.36727 5.23365 5.25048 5.14791 5.27968 4.98547C5.56983 3.4599 6.94757 3 8.18118 3C8.8126 3 9.63743 3.1679 10.1356 3.64604C10.7671 4.23546 10.7068 5.02197 10.7068 5.87784V7.89981C10.7068 8.5075 10.9587 8.77392 11.1959 9.10242C11.2798 9.2192 11.2981 9.35976 11.1922 9.44731C10.9276 9.6681 10.4568 10.0787 10.1977 10.3087L10.194 10.305"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13731_54244">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
